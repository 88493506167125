<template>
  <div class="content flex_space_between">
    <div class="left flex_direction_column">
      <div class="rich flex_direction_column">
        <div class="title">{{ info.title }}</div>
        <div class="time">{{ info.create_time }}</div>
        <div class="html" v-html="info.content"></div>
        <div class="read flex_space_between">
          <div class="read_left flex">
            <img src="@/assets/image/icon/28.png" />
            <span>{{ info.views }}人已浏览</span>
          </div>
          <!-- <div class="read_right">（来源：江苏省田径运动协会）</div> -->
        </div>
      </div>

      <div class="article flex_direction_column">
        <div class="box" v-if="info.last" @click="itemClick(info.last.id)">上一篇：{{ info.last.title }}</div>
        <div class="box" v-if="info.next" @click="itemClick(info.next.id)">下一篇：{{ info.next.title }}</div>
      </div>
    </div>
    <div class="right flex_direction_column">
      <div class="vertical_title flex">相关资讯</div>
      <div class="item flex" v-for="item in list" :key="item.id" @click="itemClick(item.id)">
        <img :src="item.image" />
        <div class="desc flex_direction_column">
          <span class="two_line">{{ item.title }}</span>
          <p>{{ item.date }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { newDetail, newTop } from '@/service/api/news'
export default {
  data() {
    return {
      info: {},
      list: []
    }
  },

  mounted() {
    // 详情页面数据
    this.newDetail(this.$route.query.id)
    this.newTop()
  },
  watch: {
    '$route.query.id': {
      handler() {
        this.newDetail(this.$route.query.id)
        this.newTop()
      },
      deep: true
    }
  },
  methods: {
    // 详情页面数据
    async newDetail(id) {
      const res = await newDetail({ id })
      this.info = res.msg
      console.log(res)
    },
    async newTop() {
      const res = await newTop()
      this.list = res.msg
    },
    itemClick(id) {
      this.newDetail(id)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 1360px;
  margin-bottom: 20px;
  position: relative;
  align-items: flex-start;
  // border: 1px solid #000;
}
.left {
  width: 912px;
  background-color: #fff;
  padding: 38px 44px 46px 44px;
  .rich {
    padding-bottom: 42px;
    border-bottom: 1px solid #eee;
    .title {
      text-align: center;
      height: 40px;
      font-size: 28px;
      line-height: 40px;
    }
    .time {
      height: 22px;
      font-size: 16px;
      line-height: 22px;
      color: #777777;
      margin-top: 22px;
      text-align: center;
      margin-bottom: 40px;
    }
    .html {
      margin: 0 20px;
    }
    .read {
      margin: 36px 20px 0 20px;
      .read_left {
        img {
          width: 18px;
          height: 13px;
          margin-right: 10px;
        }
        span {
          height: 22px;
          line-height: 22px;
          color: @color_seven;
          font-size: @font_size_16;
        }
      }
      .read_right {
        height: 20px;
        line-height: 20px;
        color: @color_six;
        font-size: @font_size_14;
      }
    }
  }
  .article {
    margin-top: 37px;
    .box {
      height: 20px;
      color: #333;
      cursor: pointer;
      line-height: 20px;
      font-size: @font_size_14;
      &:nth-child(1) {
        margin-bottom: 13px;
      }
      &:hover {
        color: @color_twenty;
      }
    }
  }
}
.right {
  width: 300px;
  padding: 30px 24px;
  background-color: #fff;
  // position: fixed;
  // top: 201px;
  // right: 271px;
  // z-index: 10;
  .item {
    cursor: pointer;
    margin-bottom: 20px;
    &:nth-child(2) {
      margin-top: 14px;
    }
    img {
      width: 128px;
      height: 80px;
      object-fit: cover;
    }
    .desc {
      height: 80px;
      margin-left: 11px;
      font-size: @font_size_16;
      justify-content: space-between;
      span {
        max-height: 47px;
        line-height: 25px;
      }
      p {
        height: 22px;
        line-height: 22px;
        color: @color_twenty;
      }
    }
  }
}
</style>
